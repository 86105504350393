<template>
  <scroller>
    <NavButtonBar>
      <nav-button @action="$router.back()">
        <i class="fa-solid fa-angle-left" />
      </nav-button>
    </NavButtonBar>
    <h1 class="text-primary mt-2 mb-50">
      {{ $t('pages.crops.name') }}
    </h1>
    <h4
      v-if="crop !== null"
      class="text-ellipsis"
    >
      <span class="mr-75">{{ crop.emoji !== null && crop.emoji !== undefined ? crop.emoji : '' }}</span> {{ crop.name }}
    </h4>

    <div class="mt-0 pt-75 mb-3">
      <template v-if="error">
        <div
          class="mt-2 mb-5 text-muted text-center d-flex flex-column px-3"
        >
          <h1 class="mt-3 mb-2">
            <i class="fa-regular fa-cloud-exclamation" />
          </h1>
          {{ $t('pages.crops.one.error-loading') }}

          <div>
            <TouchButton
              size="xs"
              class="mt-2"
              @action="load(true)"
            >
              {{ $t('therms.retry') }}
            </TouchButton>
          </div>
        </div>
      </template>
      <template v-else>
        <template v-if="loader || crop===null">
          <div class="mt-2 d-flex align-items-center justify-content-center my-5 py-2">
            <b-spinner
              variant="primary"
              style="width: 3rem; height: 3rem;"
            />
          </div>
        </template>
        <template v-else>
          <div class="mt-2 d-flex flex-column">
            <NavButtonBar class="pt-0 mb-75 d-flex align-items-center">
              <nav-button
                class="mr-75"
                color="warning"
                @action="goToEdit"
              >
                <i class="fa-regular fa-pen" />
              </nav-button>
              <span>{{ $t('therms.edit') }}</span>
            </NavButtonBar>
            <NavButtonBar class="pt-0 mb-75 d-flex align-items-center">
              <nav-button
                class="mr-75"
                color="danger"
                @action="askDelete"
              >
                <i class="fa-regular fa-trash" />
              </nav-button>
              <span>{{ $t('therms.delete') }}</span>
            </NavButtonBar>
          </div>

          <div class="mt-1 pb-50" />

          <b-list-group
            class="mb-3"
          >
            <b-list-group-item
              disable
              class="d-flex justify-content-between align-items-center"
            >
              <div class="col-5 col-sm-4 col-md-3 col-lg-3 p-0 text-ellipsis">
                <strong>{{ $t('therms.name') }}  :</strong>
              </div>
              <div class="col-7 col-sm-8 col-md-9 col-lg-9 p-0 text-ellipsis">
                {{ crop.name }}
              </div>
            </b-list-group-item>
            <b-list-group-item
              disable
              class="d-flex justify-content-between align-items-center"
            >
              <div class="col-5 col-sm-4 col-md-3 col-lg-3 p-0 text-ellipsis">
                <strong>{{ $t('therms.type') }} :</strong>
              </div>
              <div class="col-7 col-sm-8 col-md-9 col-lg-9 p-0 text-ellipsis">
                {{ cropFamily.name }}
              </div>
            </b-list-group-item>
            <b-list-group-item
              disable
              class="d-flex justify-content-between align-items-center"
            >
              <div class="col-5 col-sm-4 col-md-3 col-lg-3 p-0 text-ellipsis">
                <strong>{{ $t('therms.color') }} :</strong>
              </div>
              <div class="col-7 col-sm-8 col-md-9 col-lg-9 p-0 text-ellipsis">
                <span
                  style="height: 20px; width: 20px; border-radius: 20px; display: block;"
                  :style="{'background-color': crop.color}"
                />
              </div>
            </b-list-group-item>
            <b-list-group-item
              disable
              class="d-flex justify-content-between align-items-center"
            >
              <div class="col-5 col-sm-4 col-md-3 col-lg-3 p-0 text-ellipsis">
                <strong>{{ $t('therms.icon') }} :</strong>
              </div>
              <div class="col-7 col-sm-8 col-md-9 col-lg-9 p-0 text-ellipsis font-medium-4">
                {{ crop.emoji !== null && crop.emoji !== undefined ? crop.emoji : '' }}
              </div>
            </b-list-group-item>
            <b-list-group-item
              disable
              class="d-flex justify-content-between align-items-center"
            >
              <div class="col-5 col-sm-4 col-md-3 col-lg-3 p-0 text-ellipsis">
                <strong>{{ $t('therms.created_at') }} :</strong>
              </div>
              <div class="col-7 col-sm-8 col-md-9 col-lg-9 p-0 text-ellipsis">
                {{ createdAt }}
              </div>
            </b-list-group-item>
            <b-list-group-item
              disable
              class="d-flex justify-content-between align-items-center"
            >
              <div class="col-5 col-sm-4 col-md-3 col-lg-3 p-0 text-ellipsis">
                <strong>{{ $t('therms.updated_at') }} :</strong>
              </div>
              <div class="col-7 col-sm-8 col-md-9 col-lg-9 p-0 text-ellipsis">
                {{ updatedAt }}
              </div>
            </b-list-group-item>
          </b-list-group>

          <OneCropTypesList
            class="mb-2"
            :crop="crop"
          />
          <OneCropGradesList
            v-if="hasGrades"
            class="mb-2"
            :crop="crop"
          />
          <OneCropPackingsList
            v-if="hasPackings"
            class="mb-2"
            :crop="crop"
          />

          <div class="mb-2" />
        </template>
      </template>
    </div>
  </scroller>
</template>

<script>
import TouchButton from '@core/components/touch-button/TouchButton.vue'
import ListGroupItem from '@core/components/list-group/ListGroupItem.vue'
import NavButton from '@core/layouts/components/app-navbar/NavButton.vue'
import NavButtonBar from '@core/layouts/components/app-navbar/NavButtonBar.vue'
import OneCropTypesList from '@/views/pages/Crops/components/OneCropTypesList.vue'
import OneCropGradesList from '@/views/pages/Crops/components/OneCropGradesList.vue'
import OneCropPackingsList from '@/views/pages/Crops/components/OneCropPackingsList.vue'
import { BListGroup, BSpinner } from 'bootstrap-vue'
import { Dialog } from '@capacitor/dialog'
// eslint-disable-next-line import/no-cycle
import { NAME_CROP_ADD, NAME_CROP_EDIT, NAME_CROP_ONE } from '@/router/routes/crops'

export default {
  components: {
    TouchButton,
    NavButton,
    NavButtonBar,
    BListGroup,
    'b-list-group-item': ListGroupItem,
    BSpinner,
    OneCropTypesList,
    OneCropGradesList,
    OneCropPackingsList,
  },
  data() {
    return {
      // ui
      error: false,
      loader: false,

      // data
      crop: null,
      cropFamily: null,
    }
  },
  computed: {
    createdAt() {
      return this.$moment(this.crop.created_at)
        .format('LLL')
    },
    updatedAt() {
      return this.$moment(this.crop.updated_at)
        .format('LLL')
    },
    hasGrades() {
      return this.crop.types.filter(ct => (`${ct.options}`).split(',').includes('grades')).length >= 1
    },
    hasPackings() {
      return this.crop.types.filter(ct => (`${ct.options}`).split(',').includes('packings')).length >= 1
    },
  },
  watch: {
    '$route.params': {
      async handler() {
        setTimeout(async () => {
          await this.load(true)
        }, 500)
      },
      deep: true,
      immediate: false,
    },
  },
  async mounted() {
    this.error = false
    await this.load()
  },
  beforeDestroy() {
  },
  methods: {
    goToOne(id) {
      this.loader = true
      this.$router.push({
        name: NAME_CROP_ONE,
        params: {
          id,
        },
      })
    },
    goToAdd() {
      this.$router.push({
        name: NAME_CROP_ADD,
        params: {
          parent_id: this.crop.id,
        },
      })
    },
    goToEdit() {
      this.$router.push({
        name: NAME_CROP_EDIT,
        params: {
          id: this.crop.id,
        },
      })
    },
    async askDelete() {
      if (this.crop === null) return

      if (this.crop.is_used) {
        const { value } = await Dialog.confirm({
          title: this.$t('therms.warning').toString(),
          message: this.$t('actions.crop-used-delete-prevent.message', { brand: this.$config.brand.name }).toString(),
          okButtonTitle: this.$t('therms.continue').toString(),
          cancelButtonTitle: this.$t('therms.cancel').toString(),
        })

        if (!value) {
          return
        }
      }

      const { value } = await Dialog.confirm({
        title: this.$t('therms.deleting').toString(),
        message: this.$t('actions.crop-delete-ask.message').toString(),
        okButtonTitle: this.$t('therms.confirm').toString(),
        cancelButtonTitle: this.$t('therms.cancel').toString(),
      })

      if (value) {
        this.loader = true
        if (!await this.$store.dispatch('crops/delete', this.crop.id)) {
          await Dialog.alert({
            title: this.$t('therms.deleting').toString(),
            message: this.$t('actions.crop-delete-error.message').toString(),
            buttonTitle: this.$t('therms.close').toString(),
          })

          this.loader = false
          return
        }

        await this.$router.back()
      }
    },
    /**
     * @param {Boolean} force
     * @returns {Promise<void>}
     */
    async load(force = false) {
      this.loader = true
      try {
        if (force) {
          this.crop = null
        }

        // eslint-disable-next-line radix
        this.crop = await this.$store.dispatch('crops/find', { id: parseInt(this.$router.currentRoute.params?.id) })

        await this.$store.dispatch('crops/loadCropTypes', { id: this.crop.id, force })
        await this.$store.dispatch('crops/loadCropGrades', { id: this.crop.id, force })
        await this.$store.dispatch('crops/loadCropPackings', { id: this.crop.id, force })

        this.cropFamily = await this.$store.dispatch('cropFamilies/find', { id: this.crop.crop_family_id })
      } catch (e) {
        this.error = true
        throw e
      } finally {
        this.loader = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../../@core/scss/base/bootstrap-extended/_include';
@import '../../../@core/scss/base/components/_include';

</style>
