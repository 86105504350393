<template>
  <div>
    <div class="mb-1 pt-25 d-flex align-items-center justify-content-between">
      <h6 class="m-0 text-ellipsis">
        {{ $t('pages.crops.list.crop-grades-list.title') }}
      </h6>
      <div v-if="has && allowToAdd">
        <TouchButton
          size="xs"
          color="primary"
          @action="goToAdd"
        >
          {{ $t('therms.add') }}
        </TouchButton>
      </div>
    </div>
    <b-list-group
      class="mb-2"
    >
      <template v-if="has">
        <b-list-group-item
          v-for="item in crop.grades"
          :key="item.id"
          class="d-flex justify-content-between align-items-center"
          @action="goToOne(item.id)"
        >
          <span class="text-ellipsis">
            {{ item.name }}
          </span>
          <span class="col-2 d-flex justify-content-end m-0 p-0">
            <b-list-group-item-button
              disable
              color="primary"
            >
              <i class="fa-solid fa-angle-right" />
            </b-list-group-item-button>
          </span>
        </b-list-group-item>
      </template>
      <template v-else>
        <b-list-group-item
          class="d-flex justify-content-between align-items-center"
          @action="goToAdd"
        >
          <span class="text-ellipsis text-black-50">
            {{ $t('pages.crops.list.crop-grades-list.no-grades') }}
          </span>

          <TouchButton
            :disable="true"
            size="xs"
          >
            {{ $t('therms.add') }}
          </TouchButton>
        </b-list-group-item>
      </template>
    </b-list-group>
  </div>
</template>

<script>
import TouchButton from '@core/components/touch-button/TouchButton.vue'
import ListGroupItem from '@core/components/list-group/ListGroupItem.vue'
import ListGroupItemButton from '@core/components/list-group/ListGroupItemButton.vue'
import { BListGroup } from 'bootstrap-vue'
// eslint-disable-next-line import/no-cycle
import { NAME_CROP_GRADE_ONE, NAME_CROP_GRADE_ADD } from '@/router/routes/cropGrades'

export default {
  components: {
    TouchButton,
    BListGroup,
    'b-list-group-item': ListGroupItem,
    'b-list-group-item-button': ListGroupItemButton,
  },
  props: {
    crop: {
      type: Object,
      required: true,
    },
    allowToAdd: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      // ui

      // data
    }
  },
  computed: {
    has() {
      return this.crop.grades !== undefined && this.crop.grades !== null && this.crop.grades.length >= 1
    },
  },
  watch: {},
  methods: {
    goToOne(id) {
      this.loader = true
      this.$router.push({
        name: NAME_CROP_GRADE_ONE,
        params: {
          cropId: this.crop.id,
          id,
        },
      })
    },
    goToAdd() {
      this.loader = true
      this.$router.push({
        name: NAME_CROP_GRADE_ADD,
        params: { cropId: this.crop.id },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../../../@core/scss/base/bootstrap-extended/_include';
@import '../../../../@core/scss/base/components/_include';

</style>
